import Geocode from "react-geocode"
import styled from "styled-components"

import { ILocation, THomeAddress } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"
import { isEqual } from "src/utils/genericUtil"

import { HomeAddressForm } from "./HomeAddressForm"

export const HOME_ADDRESS_FORM_ID = "home-address-form"

export function HomeAddressStep({
  onSuccess,
  address,
  location,
  setupForGuardService,
}: {
  onSuccess: (address: THomeAddress, location: ILocation) => void
  address?: THomeAddress
  location?: ILocation
  setupForGuardService: boolean
}) {
  const formId = HOME_ADDRESS_FORM_ID
  const { t, langKeys } = useTranslate()

  async function handleSubmit(formData: THomeAddress) {
    let newLocation = location
    const dataChanged = !isEqual(formData, address)

    if (!newLocation || dataChanged) {
      const { street_name1, country, city, post_code } = formData
      const addressString = `${street_name1} ${city} ${post_code} ${country}`
      try {
        Geocode.setApiKey(import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY || "")
        const response = await Geocode.fromAddress(addressString)
        const { lat = 0, lng = 0 } =
          response.results?.[0]?.geometry?.location ?? {}
        newLocation = { latitude: lat, longitude: lng }
      } catch (e) {
        newLocation = { latitude: 0, longitude: 0 }
      }
    }

    onSuccess(
      {
        street_name1: formData.street_name1,
        street_name2: formData.street_name2,
        floor_number: formData.floor_number,
        apartment_number: formData.apartment_number,
        post_code: formData.post_code,
        country: formData.country,
        city: formData.city,
        region: formData.region,
      },
      newLocation
    )
  }

  return (
    <div>
      <TitleBox>
        <MText variant="heading2">
          {t(langKeys.home_address_wizard_home_address_title)}
        </MText>

        <Show if={setupForGuardService}>
          <MText variant="body">
            {t(langKeys.home_address_wizard_home_address_responder_body)}
          </MText>
        </Show>
      </TitleBox>

      <HomeAddressForm
        onSubmit={handleSubmit}
        initialAddress={address}
        formId={formId}
      />
    </div>
  )
}

const TitleBox = styled.div`
  display: grid;
  gap: ${spacing.XS};
  margin-bottom: ${spacing.XL};
`
