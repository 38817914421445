import styled from "styled-components"

import {
  countryData,
  getProvinceArray,
} from "src/components/Account/BillingPortal/countryData"
import { THomeAddress } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSelect } from "src/ui/MSelect/MSelect"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

import { useHomeAddressForm } from "./useHomeAddressForm"

export function HomeAddressForm({
  onSubmit,
  initialAddress,
  error,
  formId,
}: {
  onSubmit: (formData: THomeAddress) => void
  initialAddress?: THomeAddress
  error?: boolean
  formId?: string
}) {
  const { address, handleInputChange } = useHomeAddressForm(initialAddress)
  const provinces = getProvinceArray(address.country)
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <FormBox
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(address)
        }}
        id={formId}
      >
        <MSelect
          label={t(langKeys.address_form_country)}
          value={address?.country}
          options={[
            {
              label: "",
              value: "",
              hidden: true,
            },
            ...countryData.map((country) => ({
              key: country.code,
              label: country.label,
              value: country.code,
            })),
          ]}
          onChange={(value) => {
            handleInputChange({ country: value })
          }}
        />

        <MTextField
          label={t(langKeys.address_form_street_name_1)}
          name="street_name1"
          required
          autoComplete="street_name1"
          value={address?.street_name1}
          onChange={(value) => handleInputChange({ street_name1: value })}
        />

        {(initialAddress?.street_name2?.length ?? 0) > 0 && (
          <MTextField
            label={t(langKeys.address_form_street_name_2)}
            name="street_name2"
            autoComplete="street_name2"
            value={address?.street_name2}
            onChange={(value) => handleInputChange({ street_name2: value })}
          />
        )}

        <Area>
          <MTextField
            label={t(langKeys.floor)}
            name="floor_number"
            value={address?.floor_number}
            onChange={(value) => handleInputChange({ floor_number: value })}
          />

          <MTextField
            label={t(langKeys.flat_or_suite)}
            name="apartment_number"
            value={address?.apartment_number}
            onChange={(value) => handleInputChange({ apartment_number: value })}
          />

          <MTextField
            label={t(langKeys.address_form_city)}
            name="city"
            autoComplete="city"
            required
            value={address?.city}
            onChange={(value) => handleInputChange({ city: value })}
          />

          {provinces.length > 0 && (
            <MSelect
              id="state"
              label={t(langKeys.address_form_state)}
              value={address?.region}
              options={[
                {
                  label: "",
                  value: "",
                  hidden: true,
                },
                ...provinces.map((province) => ({
                  key: province.code,
                  label: province.label,
                  value: province.code,
                })),
              ]}
              onChange={(value) => {
                handleInputChange({
                  region: value,
                })
              }}
              autoComplete="state"
              required
            />
          )}

          <MTextField
            label={t(langKeys.address_form_postcode)}
            name="post_code"
            required
            autoComplete="post_code"
            value={address?.post_code}
            onChange={(value) => handleInputChange({ post_code: value })}
          />
        </Area>
      </FormBox>
      {error && (
        <AlertBox type="error">
          {t(langKeys.failed_something_went_wrong)}
        </AlertBox>
      )}
    </div>
  )
}

const FormBox = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing.L};
  padding: ${spacing.XS2};
`

const Area = styled.div`
  display: grid;
  gap: ${spacing.L};
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`

const AlertBox = styled(MBanner)`
  margin-bottom: ${spacing.L};
`
